import { Link } from "react-router-dom"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import emailjs from "@emailjs/browser"
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback, useEffect } from "react";

function ContactForm() {
    const schema = yup
        .object({
            name: yup
                .string()
                .max(50)
                .required("Veuillez mettre votre nom"),
            firstname: yup
                .string()
                .max(50)
                .required("Veuillez mettre votre prénom"),
            mail: yup
                .string()
                .email("Veuillez mettre une adresse email valide")
                .max(255)
                .required("Veuillez mettre votre adresse email"),
            validate: yup
                .bool()
                .required("Veuillez accepter les conditions")
                .oneOf([true], "Veuillez accepter les conditions"),
            message: yup
                .string()
                .required("Veuillez mettre votre message")
        })
        .required()
    
    const { register, formState: {errors}, handleSubmit} = useForm({resolver: yupResolver(schema)})

    const YourReCaptchaComponent = () => {
        const { executeRecaptcha } = useGoogleReCaptcha();
      
        // Create an event handler so you can call the verification on button click event or form submit
        const handleReCaptchaVerify = useCallback(async () => {
          if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
          }
      
          const token = await executeRecaptcha('yourAction');
          // Do whatever you want with the token
        }, [executeRecaptcha]);
      
        // You can use useEffect to trigger the verification as soon as the component being loaded
        useEffect(() => {
          handleReCaptchaVerify();
        }, [handleReCaptchaVerify]);
      };

    const onSubmit = (data, r) => {
        const templateID = "template_ckogrym";
        const serviceID = "service_cor849d";
        sendFeedback(serviceID, templateID, {
            name: data.name,
            firstname: data.firstname,
            mail: data.mail,
            subject: data.subject,
            message: data.message,
            reply_to: r.target.reset(),
        })
    }

    const sendFeedback = (serviceId, templateId, variables) => {
        emailjs
          .send(serviceId, templateId, variables, 'ZBYxTo1PMl5CYob6d')
          .then((res) => {
            console.log('succes');
          })
          .catch((err) => console.error('Il y a une erreur'));
      }

    return(
        <div className="w-full flex justify-center items-center">
            <form className="lg:w-5/6 w-11/12 rounded-3xl shadow-[0_0px_40px_-15px_rgba(0,0,0,1)] lg:py-5 py-4 lg:px-10 px-4 space-y-6" onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full grid lg:grid-cols-2 grid-cols-1 gap-x-8">
                    <div className="w-full flex flex-col items-start justify-start space-y-4">
                        <div className="w-full flex flex-col justify-start items-start space-y-1">
                            <label htmlFor="name">Nom*</label>
                            <input 
                                for="contact"
                                type="text" 
                                name="name" 
                                id="name" 
                                className="bg-[#F1F1F1] rounded py-1 px-3 w-full"
                                {...register("name")}
                            />
                            { errors.name && <p className="text-[#FF1D25]">{errors.name.message}</p>}
                        </div>
                        <div className="w-full flex flex-col justify-start items-start space-y-1">
                            <label htmlFor="firstname">Prénom*</label>
                            <input
                                for="contact"
                                type="text" 
                                name="firstname" 
                                id="firstname" 
                                className="bg-[#F1F1F1] rounded py-1 px-3 w-full" 
                                {...register("firstname")}
                            />
                            { errors.firstname && <p className="text-[#FF1D25]">{errors.firstname.message}</p>}
                        </div>
                        <div className="w-full flex flex-col justify-start items-start space-y-1">
                            <label htmlFor="mail">Adresse email*</label>
                            <input 
                                for="contact"
                                type="email" 
                                name="mail" 
                                id="mail" 
                                className="bg-[#F1F1F1] rounded py-1 px-3 w-full" 
                                {...register("mail")}
                            />
                            { errors.mail && <p className="text-[#FF1D25]">{errors.mail.message}</p>}
                        </div>
                        <div className="w-full flex flex-col justify-start items-start space-y-1">
                            <label htmlFor="subject">Sujet*</label>
                            <input 
                                for="contact"
                                type="text" 
                                name="subject" 
                                id="subject" 
                                className="bg-[#F1F1F1] rounded py-1 px-3 w-full" 
                                {...register("subject")}
                            />
                        </div>
                    </div>
                    <div className="w-full flex flex-col justify-start items-start space-y-1">
                        <label htmlFor="message">Message*</label>
                        <textarea 
                            for="contact"
                            name="message" 
                            id="message" 
                            className="w-full h-full bg-[#F1F1F1] rounded py-1 px-3"
                            {...register("message")}
                        >
                        </textarea>
                        { errors.message && <p className="text-[#FF1D25]">{errors.message.message}</p>}
                    </div>
                </div>
                <div className="w-full flex flex-row justify-center items-center space-x-4">
                    <input 
                        for="contact"
                        type="checkbox" 
                        name="validate" 
                        id="validate"
                        {...register("validate")}
                    />
                    <label htmlFor="validate" className="lg:text-lg text-sm">Ce formulaire permet de recueillir vos coordonnées afin que nous puissions vous recontacter au plus vite et répondre à votre demande. Les données de ce formulaire sont conservées le temps de répondre à votre demande.</label>
                </div>
                { errors.validate && <p className="text-[#FF1D25]">{errors.validate.message}</p>}
                <p className="lg:text-base text-xs font-normal underline">Ce site est protégé par reCAPTCHA, la Politique de Confidentialité et les Conditions d'utilisation de Google.</p>
                <div className="w-full flex flex-col space-y-1 lg:text-base text-xs font-normal">
                    <p>Ce formulaire permet de recueillir vos coordonnées afin que nous puissions vous recontacter au plus vite et répondre à votre demande.</p>
                    <p>Les données recueillies via ce formulaire de contact, sont uniquement destinées au Groupe AMBITION à des fins d’usage interne et ne seront jamais retransmises à des tiers non autorisés.</p>
                    <p>Les données de ce formulaire sont conservées le temps de répondre à votre demande.</p>
                </div>
                <GoogleReCaptchaProvider reCaptchaKey="6Lfq-1koAAAAABkTyBN2vcn3oZexpXinBokpZQ1T">
                    <YourReCaptchaComponent />
                </GoogleReCaptchaProvider>
                <div className="w-full flex flex-col items-start justify-start space-y-1">
                    <input 
                        for="contact"
                        type="submit" 
                        value="Envoyer" 
                        className="bg-gradient-to-r from-[#931212] to-[#FF1D25] px-4 py-1 rounded text-white"
                    />
                    <p className="lg:text-base text-xs font-normal underline">*Champs obligatoires</p>
                </div>
                <p className="lg:text-base text-xs font-normal underline">Si vous avez des questions concernant vos données personnelles, veuillez consulter la <Link to="/">Politique de confidentialité</Link></p>
            </form>
        </div>
    )
}

export default ContactForm