import BottomWave from "../../components/BottomWave"
import WaveHeader from "../../components/WaveHeader"
import BgWave1 from "../../assets/BgWave1.png"
import ContactForm from "../../components/ContactForm"

function Contact() {
    return(
        <div className="w-full flex flex-col justify-center items-center relative pb-32">
            <div className="w-full flex justify-center items-center relative max-h-[50vh]">
                <img src={BgWave1} alt="BgWave1" className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                <div className="absolute w-full h-full bg-[#931212]/40 top-0"></div>
                <WaveHeader />
                <div className="w-full h-1 absolute bg-white bottom-0"></div>
                <h1 className="absolute top-[15%] text-white font-bold lg:text-7xl text-4xl lg:w-2/3 w-5/6 text-center drop-shadow-xl">CONTACT</h1>
            </div>
            <div className="flex flex-col w-5/6 justify-center items-center space-y-12 pb-16 relative">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">Besoin d'un renseignement ?</h2>
                <ContactForm />
            </div>
            <div className="flex flex-col w-5/6 justify-center items-center space-y-12 lg:pb-16 pb-8 relative">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">Envie de nous rejoindre ?</h2>
                <div className="flex flex-row items-center justify-center font-bold tet-3xl space-x-6">
                    <p>Consultez toutes nos offres en cliquant ici</p>
                    <a href="https://www.groupeambition.fr" className="py-2 px-6 bg-gradient-to-r from-[#212A68] to-[#0066CC] rounded-full text-white" target="_blank" rel="noreferrer">AMBITION</a>
                </div>
            </div>
            <BottomWave />
        </div>
    )
}

export default Contact