import { Outlet, Route, Routes } from "react-router-dom"
import Accueil from "./Accueil"
import { Fragment } from "react";
import Header from "../components/Header"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import Presentation from "./Presentation";
import MVS from "./MVS";
import PID from "./PID";
import Chantiers from "./Chantiers";
import Actualites from "./Actualites";
import Actualite from "./Actualite";
import Contact from "./Contact";
import Mentions from "./Mentions";
import Confidentialite from "./Confidentialite";
import ScrollToTop from "../components/BackToTop";
import { Test } from "./Test";

function Layout() {
  return(
    <Fragment>
      <Navbar />
      <ScrollToTop />
      <Outlet />
      <Footer />
    </Fragment>
  )
}

function LayoutIndex() {
  return(
    <Fragment>
      <Header />
      <Navbar />
      <ScrollToTop />
      <Accueil />
      <Footer />
    </Fragment>
  )
}

function Content() {
  return(
    <Routes>
      <Route index element={<LayoutIndex />} />
      <Route path="/" element={<Layout />}>
        <Route path="presentation" element={<Presentation />} />
        <Route path="mvs" element={<MVS />} />
        <Route path="pid" element={<PID />} />
        <Route path="chantiers" element={<Chantiers />} />
        <Route path="actualites" element={<Actualites />} />
        <Route path="actualite/:id" element={<Actualite />} />
        <Route path="contact" element={<Contact />} />
        <Route path="mentions" element={<Mentions />} />
        <Route path="confidentialite" element={<Confidentialite />} />
        <Route path="test" element={<Test />} />
      </Route>
    </Routes>
  )
}

function App() {
  return (
    <div className="App lg:text-xl text-lg lg:font-medium font-normal relative overflow-x-hidden">
      <Content />
    </div>
  );
}

export default App;
