import { Link } from "react-router-dom"
import BottomWave from "../../components/BottomWave"
import Pres1 from "../../assets/Presentation/Pres1.png"
import WaveHeader from "../../components/WaveHeader"
import BgWave1 from "../../assets/BgWave1.png"
import Pres2 from "../../assets/Presentation/Pres2.png"
import Lola from "../../assets/Presentation/Lola.png"
import rapport from "../../flyers/Rapport_Activite_GA_2021.pdf"

function Presentation() {
    return(
        <div className="w-full flex flex-col justify-center items-center relative pb-32">
            <div className="w-full flex justify-center items-center relative max-h-[50vh]">
                <img src={BgWave1} alt="BgWave1" className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                <div className="absolute w-full h-full bg-[#931212]/40 top-0"></div>
                <WaveHeader />
                <div className="w-full h-1 absolute bg-white bottom-0"></div>
                <h1 className="absolute top-[15%] text-white font-bold lg:text-7xl text-4xl lg:w-2/3 w-5/6 text-center drop-shadow-xl">PRÉSENTATION</h1>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center lg:space-y-12 space-y-8 lg:pb-16 pb-12 lg:pt-0 pt-4 relative">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">Qui sommes-nous ?</h2>
                <div className="w-full flex flex-col lg-space-y-8 space-y-5">
                    <p>L’association <span className="text-[#FF1D25] font-bold">GLOBAL AXE</span> loi 1901 s’apparente à un acteur majeur sur le champ de l’Accueil Hébergement Insertion (AHI) et de l’insertion socioprofessionnelle. Elle naît, en 2015, d’une volonté de favoriser l’insertion des publics fragilisés en leur proposant un accompagnement global par le logement et la voie socio-professionnelle.</p>
                    <p>L’association, riche d’une trentaine de salarié(e)s permanents, œuvre chaque jour afin de répondre à des besoins toujours plus importants. Elle s’inscrit au sein d’un maillage territorial fort soutenu par le groupement associatif <span className="text-[#FF1D25] font-bold">AMBITION</span> et par un large réseau partenarial. Ensemble, ils contribuent à développer une société plus juste, inclusive et solidaire.</p>
                </div>
                <div className="w-full flex lg:flex-row flex-col-reverse justify-between items-center lg:space-y-0">
                    <div className="lg:w-[50%] w-full flex flex-col lg-space-y-8 space-y-5 lg:pt-0 pt-8">
                        <p>Quelles sont nos missions ?</p>
                        <p>L'accueil, l'hébergement et l'accompagnement socioprofessionnelle</p>
                        <p>Ces trois missions sont assurées par trois services :</p>
                        <ul className="list-inside list-disc lg:pl-10 pl-4">
                            <li>La Maison de la Veille Sociale (MVS). <Link to="/mvs" className="hover:underline">Voir -></Link></li>
                            <li>Le Service Ingénierie et Développement. <Link to="/pid" className="hover:underline">Voir -></Link></li>
                            <li>Le Service Insertion par l'Activité Économique. <Link to="/chantiers" className="hover:underline">Voir -></Link></li>
                        </ul>
                        <p>L’association <span className="text-[#FF1D25] font-bold">GLOBAL AXE</span> appartient au groupement de coopération <span className="text-[#FF1D25] font-bold">AMBITION</span> réunissant d’autres entités spécialisées dans le secteur de l’insertion et de l’hébergement. Ensemble, nous mutualisons nos compétences pour développer des services et des actions qui répondent aux besoins du territoire.</p>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center relative">
                        <img src={Pres1} alt="Pres1" className="w-[60%]" />
                        <div className="w-[50px] h-[50px] rounded-full border-4 border-[#FF1D25] absolute -z-10 top-0 left-10"></div>
                        <div className="w-[100px] h-[100px] rounded-full border-4 border-[#931212] absolute -z-50 lg:-bottom-10 bottom-6 lg:right-32 right-16"></div>
                    </div>
                </div>
                <a href="test.pdf" className="bg-[#FF1D25] rounded-full py-2 px-12 text-white" download>Brochure</a>
                <div className="w-[40px] h-[40px] rounded-full border-4 border-[#931212] absolute -z-10 -top-5 -left-5"></div>
                <div className="w-[100px] h-[100px] rounded-full border-4 border-[#FF1D25] absolute z-50 -top-32 right-0"></div>
            </div>
            <div className="w-5/6 flex lg:flex-row flex-col-reverse justify-between items-center pb-16">
                <div className="lg:w-[45%] w-full flex justify-center items-center relative lg:pt-0 pt-8">
                    <img src={Pres2} alt="Pres2" className="w-[80%]" />
                    <div className="w-[100px] h-[100px] rounded-full border-4 border-[#931212] absolute -z-10 lg:top-0 top-5 left-10"></div>
                    <div className="w-[50px] h-[50px] rounded-full border-4 border-[#FF1D25] absolute -z-50 -bottom-10 right-32"></div>
                </div>
                <div className="lg:w-[50%] w-full flex flex-col justify-center items-center space-y-12">
                    <h2 className="lg:text-4xl text-3xl font-bold text-center">Informations légales et chiffres sur l'activité</h2>
                    <div className="w-full flex flex-col justify-center items-center space-y-8">
                        <p>Veuillez consulter nos rapports d'activité</p>
                        <a href={rapport} className="bg-[#FF1D25] rounded-full py-2 px-12 text-white" download="rapport.pdf">Téléchargez nos chiffres sur l'activité</a>
                    </div>
                </div>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center pb-16 space-y-12 relative">
                <h2 className="lg:text-4xl text-3xl font-bold">Espace presse</h2>
                <div className="w-full flex flex-col space-y-12">
                    <p>Contact Relation Presse</p>
                    <div className="flex flex-row items-start space-x-5">
                        <img src={Lola} alt="Lola" className="w-[100px]" />
                        <div className="flex flex-col space-y-5">
                            <p className="font-bold">Poiret Lola</p>
                            <div>
                                <p>Chargée de communication</p>
                                <p>l.poiret@globalaxe08.fr</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="lg:w-[150px] w-[80px] lg:h-[150px] h-[80px] rounded-full border-4 border-[#931212] absolute -z-10 lg:-top-10 top-0 lg:right-10 right-0"></div>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center pb-24">
                <h2 className="lg:text-4xl text-3xl font-bold">Ressources</h2>
            </div>
            <BottomWave />
        </div>
    )
}

export default Presentation