import WaveHeader from "../../components/WaveHeader"
import BgWave1 from "../../assets/BgWave1.png"

function Confidentialite() {
    return(
        <div className="w-full flex flex-col justify-center items-center relative pb-32">
            <div className="w-full flex justify-center items-center relative max-h-[50vh]">
                <img src={BgWave1} alt="BgWave1" className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                <div className="absolute w-full h-full bg-[#931212]/40 top-0"></div>
                <WaveHeader />
                <div className="w-full h-1 absolute bg-white bottom-0"></div>
                <h1 className="absolute top-[15%] text-white font-bold lg:text-7xl text-4xl lg:w-2/3 w-5/6 text-center drop-shadow-xl">POLITIQUE DE CONFIDENTIALITÉ</h1>
            </div>
            <div className="w-11/12 flex flex-col justify-start items-start space-y-6">
                <div>
                    <p>Adresse du site : globalaxe.fr</p>
                    <p>Dénomination sociale : Global Axe</p>
                </div>
                <p>Type de site : vitrine</p>
                <p className="font-bold text-2xl">Le but de cette politique de confidentialité</p>
                <p>Le but de cette politique de confidentialité est d’informer les utilisateurs de notre site des données personnelles que nous recueillerons ainsi que les informations suivantes, le cas échéant :</p>
                <div>
                    <p>Les données personnelles que nous recueillerons</p>
                    <p>L’utilisation des données recueillies</p>
                    <p>Qui a accès aux données recueillies</p>
                    <p>Les droits des utilisateurs du site</p>
                    <p>La politique de cookies du site</p>
                </div>
                <p>Cette politique de confidentialité fonctionne parallèlement aux conditions générales d’utilisation de notre site.</p>
                <p className="font-bold text-2xl">Lois applicables</p>
                <p>Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l’informatique, aux fichiers et aux libertés.</p>
                <p>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique.</p>
                <p>Conformément au Règlement général sur la protection des données (RGPD), cette politique de confidentialité est conforme aux règlements suivants.</p>
                <p>Les données à caractère personnel doivent être :</p>
                <div>
                    <p>traitées de manière licite, loyale et transparente au regard de la personne concernée (licéité, loyauté, transparence) ;</p>
                    <p>collectées pour des finalités déterminées, explicites et légitimes, et ne pas être traitées ultérieurement d’une manière incompatible avec ces finalités; le traitement ultérieur à des fins archivistiques dans l’intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques n’est pas considéré, conformément à l’article 89, paragraphe 1, comme incompatible avec les finalités initiales (limitation des finalités) ;</p>
                    <p>adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont traitées (minimisation des données) ;</p>
                    <p>exactes et, si nécessaire, tenues à jour; toutes les mesures raisonnables doivent être prises pour que les données à caractère personnel qui sont inexactes, eu égard aux finalités pour lesquelles elles sont traitées, soient effacées ou rectifiées sans tarder (exactitude) ;</p>
                    <p>conservées sous une forme permettant l’identification des personnes concernées pendant une durée n’excédant pas celle nécessaire au regard des finalités pour lesquelles elles sont traitées; les données à caractère personnel peuvent être conservées pour des durées plus longues dans la mesure où elles seront traitées exclusivement à des fins archivistiques dans l’intérêt public, à des fins de recherche scientifique ou historique ou à des fins statistiques conformément à l’article 89, paragraphe 1, pour autant que soient mises en œuvre les mesures techniques et organisationnelles appropriées requises par le règlement afin de garantir les droits et libertés de la personne concernée (limitation de la conservation) ;</p>
                    <p>traitées de façon à garantir une sécurité appropriée des données à caractère personnel, y compris la protection contre le traitement non autorisé ou illicite et contre la perte, la destruction ou les dégâts d’origine accidentelle, à l’aide de mesures techniques ou organisationnelles appropriées (intégrité et confidentialité).</p>
                </div>
                <p>Le traitement n’est licite que si, et dans la mesure où, au moins une des conditions suivantes est remplie :</p>
                <div>
                    <p>la personne concernée a consenti au traitement de ses données à caractère personnel pour une ou plusieurs finalités spécifiques ;</p>
                    <p>le traitement est nécessaire à l’exécution d’un contrat auquel la personne concernée est partie ou à l’exécution de mesures précontractuelles prises à la demande de celle-ci ;</p>
                    <p>le traitement est nécessaire au respect d’une obligation légale à laquelle le responsable du traitement est soumis ;</p>
                    <p>le traitement est nécessaire à la sauvegarde des intérêts vitaux de la personne concernée ou d’une autre personne physique ;</p>
                    <p>le traitement est nécessaire à l’exécution d’une mission d’intérêt public ou relevant de l’exercice de l’autorité publique dont est investi le responsable du traitement ;</p>
                    <p>le traitement est nécessaire aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une protection des données à caractère personnel, notamment lorsque la personne concernée est un enfant.</p>
                </div>
                <p>Si nous constatons des incohérences, nous modifierons notre politique pour nous conformer à la loi pertinente.</p>
                <p>Tout litige en relation avec l’utilisation du site globalaxe.fr est soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Paris.</p>
                <p className="font-bold text-2xl">Consentement</p>
                <p>Les utilisateurs conviennent qu’en utilisant notre site, ils consentent à :</p>
                <p>les conditions énoncées dans la présente politique de confidentialité et la collecte, l’utilisation et la conservation des données énumérées dans la présente politique.</p>
                <p className="font-bold text-2xl">Données personnelles que nous collectons</p>
                <p>Données collectées automatiquement</p>
                <p>Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir et conserver les renseignements suivants :</p>
                <div>
                    <p>Lieu</p>
                    <p>Détails matériels et logiciels</p>
                    <p>Liens cliquables par l’utilisateur lors de la navigation sur le site</p>
                    <p>Contenu que l’utilisateur consulte sur le site</p>
                    <p>Données recueillies de façon non automatique</p>
                </div>
                <p>Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines fonctions sur notre site :</p>
                <div>
                    <p>Nom et Prénom</p>
                    <p>Email</p>
                </div>
                <div>Ces données peuvent être recueillies au moyen des méthodes suivantes :</div>
                <p>Utilisation du formulaire de contact</p>
                <p>Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif énoncé dans cette politique de confidentialité. Nous ne recueillerons pas de données supplémentaires sans vous en informer au préalable.</p>
                <p className="font-bold text-2xl">Comment nous utilisons les données personnelles</p>
                <p>Les données personnelles recueillies sur notre site seront utilisées uniquement aux fins précisées dans la présente politique ou indiquées sur les pages pertinentes de notre site. Nous n’utiliserons pas vos données au-delà de ce que nous divulguerons.</p>
                <p>Les données que nous recueillons automatiquement sont utilisées aux fins suivantes :</p>
                <p>Statistiques</p>
                <p>Les données que nous recueillons lorsque l’utilisateur exécute certaines fonctions peuvent être utilisées aux fins suivantes :</p>
                <p>Recontacter</p>
                <p className="font-bold text-2xl">Avec qui nous partageons les données personnelles</p>
                <p>Employés</p>
                <p>Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique.</p>
                <p>Tierces parties</p>
                <p>Nous ne partageons pas vos données personnelles à des parties tierces</p>
                <p>Autres divulgations</p>
                <p>Nous nous engageons à ne pas vendre ou partager vos données avec des tiers, sauf dans les cas suivants :</p>
                <div>
                    <p>si la loi l'exige</p>
                    <p>si elle est requise pour toute procédure judiciaire</p>
                    <p>pour prouver ou protéger nos droits légaux</p>
                </div>
                <p>Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n’avons pas de contrôle sur leurs politiques et pratiques de confidentialité.</p>
                <p className="font-bold text-2xl">Combien de temps nous stockons les données personnelles</p>
                <p>Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les fins pour lesquelles elles sont recueillies.</p>
                <p className="font-bold text-2xl">Comment nous protégeons vos données personnelles</p>
                <p>Afin d’assurer la protection de votre sécurité, nous utilisons le protocole de sécurité de la couche transport pour transmettre des renseignements personnels dans notre système.</p>
                <p className="font-bold text-2xl">Mineurs</p>
                <p>Le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal pour que leurs données soient recueillies, traitées et utilisées.</p>
                <p className="font-bold text-2xl">Vos droits en tant qu’utilisateur</p>
                <p>En vertu du RGPD, les utilisateurs ont les droits suivants en tant que personnes concernées :</p>
                <div>
                    <p>droit d'accès</p>
                    <p>droite de rectification</p>
                    <p>droit à l'effacement</p>
                    <p>droit de restreindre le traitement</p>
                    <p>droit à la portabilité des données</p>
                    <p>droit d'objection</p>
                </div>
                <p>Vous trouverez de plus amples informations sur ces droits au chapitre 3 (art 12-23) du RGPD. https://www.cnil.fr/fr/reglement-europeen-protection-donnees/chapitre3</p>
                <p className="font-bold text-2xl">Comment modifier, supprimer ou contester les données recueillies</p>
                <p>Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre, veuillez communiquer avec notre agent de protection de la vie privée ici :</p>
                <p>Nous utilisons les types de cookies suivants sur notre site :</p>
                <p className="font-bold text-2xl">Cookies fonctionnels</p>
                <p>Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu’elles soient sauvegardées pour vos prochaines visites.</p>
                <p className="font-bold text-2xl">Cookies analytiques</p>
                <p>Cela nous permet d’améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et sur lequel vous vous accrochez en utilisant notre site.</p>
                <p>Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la qualité de votre expérience d’utilisation.</p>
                <p className="font-bold text-2xl">Cookies tiers</p>
                <p>Nous pouvons utiliser des cookies tiers sur notre site pour atteindre les objectifs suivants :</p>
                <p>Obtenir des statistiques d’utilisation</p>
                <p className="font-bold text-2xl">Modifications</p>
                <p>Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité avec la loi et de tenir compte de tout changement à notre processus de collecte de données. Nous recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel des changements apportés à cette politique.</p>
                <p className="font-bold text-2xl">Contact</p>
                <p>Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous en utilisant ce qui suit :</p>
                <div>
                    <p>l.poiret@globalaxe08.fr</p>
                    <p>Global Axe, 27 Rue Jules Verne, 08000 Charleville–Mézières</p>
                </div>
                <p className="italic">Date d’entrée en vigueur : le 27 Septembre 2023</p>
            </div>
        </div>
    )}

export default Confidentialite