import BottomWave from "../../components/BottomWave"
import WaveHeader from "../../components/WaveHeader"
import BgWave1 from "../../assets/BgWave1.png"
import MVS1 from "../../assets/MVS/MVS1.png"
import MVS2 from "../../assets/MVS/MVS2.png"

function MVS() {
    return(
        <div className="w-full flex flex-col justify-center items-center relative pb-32">
            <div className="w-full flex justify-center items-center relative max-h-[50vh]">
                <img src={BgWave1} alt="BgWave1" className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                <div className="absolute w-full h-full bg-[#931212]/40 top-0"></div>
                <WaveHeader />
                <div className="w-full h-1 absolute bg-white bottom-0"></div>
                <h1 className="absolute top-[15%] text-white font-bold lg:text-7xl text-4xl lg:w-2/3 w-5/6 text-center drop-shadow-xl">MAISON DE LA VEILLE SOCIALE</h1>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center lg:space-y-12 space-y-4 lg:pt-0 pt-8 pb-16 relative">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">Qu'est-ce que la Maison de la Veille Sociale ?</h2>
                <div className="w-full flex flex-col space-y-8">
                    <p>Il s’agit d’un lieu qui regroupe l’ensemble des dispositifs destinés à tout public à la rue ou risquant de l’être.</p>
                    <p>La <span className="text-[#FF1D25] font-bold">MVS</span> propose un abri d’urgence de nuit et/ou de jour, destiné à accueillir le public à la rue dans le but d’une réinsertion par le logement et/ou l’hébergement. Elle permet, en parallèle, une prise en charge en hébergement d’urgence à moyen terme pour les situations qui le nécessitent.</p>
                    <p>Elle est porteuse de la plateforme téléphonique du 115, assurant une permanence 24h sur 24.</p>
                    <p>Elle abrite également le Samu Social 115, une équipe de maraudeurs professionnels et de bénévoles de la Croix Rouge chargée d’aller à la rencontre des personnes isolées, directement dans les rues et ce, sur l’ensemble du département.</p>
                    <p>De plus, elle est porteuse du SIAO (Service Intégré d’Accueil et d’Orientation) qui assure la coordination des dispositifs d’hébergement et d’accompagnement financés par l’Etat, le recensement des demandes d’hébergement et de logement, l’orientation cohérente des personnes en fonction des situations et la mission d’observatoire permettant sociale d’établir un état des lieux des besoins et évaluer les réponses à apporter.</p>
                </div>
                <div className="w-[100px] h-[100px] rounded-full border-4 border-[#FF1D25] absolute z-50 lg:-top-16 -top-10 lg:right-0 -right-16"></div>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center lg:space-y-12 space-y-4 pb-16 relative">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">SIAO/115</h2>
                <div className="w-full flex flex-col space-y-8">
                    <p>Le <span className="text-[#FF1D25] font-bold">Service Intégré d’Accueil et d’Orientation</span> (SIAO 115) est un service public sous l’autorité de l’Etat dont les missions principales sont :</p>
                    <ul className="list-inside list-disc lg:pl-10 pl-4 lg:space-y-2 space-y-1">
                        <li>Recenser l’ensemble des demandes d’hébergement d’urgence, de stabilisation, d’insertion ou de logement adapté, ainsi que l’offre disponible correspondante.</li>
                        <li>Assurer l’orientation des personnes après une évaluation sociale en fonction de leur situation de détresse.</li>
                        <li>Favoriser la fluidité de l’accès au logement des personnes.</li>
                        <li>Assurer la coordination du dispositif de Veille Sociale.</li>
                        <li>Constituer l’observation sociale afin de mieux évaluer les besoins et les réponses à apporter.</li>
                    </ul>
                    <p>Le SIAO gère la Commission Partenarial d'Orientation du département.</p>
                    <p>Le 115 est le numéro d’appel d’urgence sociale destinée à tous les publics, gratuit et joignable 7 jours sur 7 et 24h sur 24. Il est géré par 3 écoutants sociaux, qui font une 1ère évaluation de la situation et propose une mise à l’abri ou une orientation sur un dispositif d’urgence.</p>
                </div>
                <div className="w-[80px] h-[80px] rounded-full border-4 border-[#FF1D25] absolute z-50 -top-16 -left-10"></div>
                <div className="w-[50px] h-[50px] rounded-full border-4 border-[#FF1D25] absolute z-50 lg:-top-12 -top-20 right-48"></div>
                <div className="w-[120px] h-[120px] rounded-full border-4 border-[#931212] absolute z-50 -top-20 -right-16"></div>
            </div>
            <div className="w-5/6 flex lg:flex-row flex-col justify-between items-center pb-16 lg:space-y-0 space-y-6">
                <div className="lg:w-[50%] w-full flex flex-col justify-center items-center lg:space-y-12 space-y-4">
                    <h2 className="text-4xl font-bold text-center">Travaux de l'Observatoire</h2>
                    <div className="w-full flex flex-col justify-center items-center lg:space-y-8 space-y-2">
                        <p>Parmi les missions assurées par le Service Intégré de l’Accueil et de l’Orientation (SIAO 115), la mise en place d’observatoires locaux est nécessaire pour relever les besoins des publics. Ainsi, l’observatoire permet la création de données et d’études sur l’activité dans le secteur de l’accueil, de l’hébergement, de l’insertion et du logement.</p>
                        <p>Retrouvez prochainement les différents travaux de l’Observatoire.</p>
                    </div>
                </div>
                <div className="lg:w-[45%] w-full flex justify-center items-center relative">
                    <img src={MVS1} alt="MVS1" className="w-[65%]" />
                    <div className="w-[150px] h-[150px] rounded-full border-4 border-[#931212] absolute -z-50 lg:-bottom-10 bottom-0 right-24"></div>
                </div>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center lg:space-y-12 space-y-4 pb-16 relative">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">Samu social</h2>
                <div className="w-full flex flex-col space-y-8">
                    <p>Une équipe de maraudeurs professionnels et de bénévoles de la <span className="text-[#FF1D25] font-bold">Croix Rouge</span>C sillonne les rues des villes principales du département afin d’aller à la rencontre des personnes se trouvant dans la rue. Les maraudes s’effectuent de 16h à 20h du lundi au vendredi et peuvent être étendues les weekends et jours fériés.</p>
                </div>
                <div className="w-[50px] h-[50px] rounded-full border-4 border-[#FF1D25] absolute z-50 -top-16 -left-12"></div>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center space-y-12 pb-16 relative">
                <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-x-16 gap-y-6 w-full">
                    <div className="flex flex-col justify-start items-center lg:space-y-12 space-y-4">
                        <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">Accueil de jour</h2>
                        <div className="w-full flex flex-col lg:space-y-8 space-y-2">
                            <p>Le service d’<span className="text-[#FF1D25] font-bold">accueil de jour</span> accueille les publics fragilisés du lundi au vendredi, de 10h à 16h (ces horaires peuvent changer selon les conditions météorologiques ou sanitaires).</p>
                            <p>Y sont proposées des prestations de laverie et de bagagerie ainsi que la possibilité de cuisiner avec fourniture de denrées alimentaires. Des douches sont également accessibles pendant les heures d’ouvertures.</p>
                            <p>Le service comporte un chenil pour les personnes possédant un animal et un pôle informatique afin de procéder à des démarches en ligne. Des actions d’animation sont régulièrement proposées.</p>
                            <p>Enfin, nos professionnels proposent un accompagnement des usagers vers un parcours résidentiel, avec possibilité de domiciliation.</p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-center lg:space-y-12 space-y-4 relative">
                        <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">Accueil de nuit</h2>
                        <div className="w-full flex flex-col lg:space-y-8 space-y-2">
                            <p>L’<span className="text-[#FF1D25] font-bold">accueil de nuit</span> (ou mise à l’abri) est un dispositif d’hébergement d’urgence pour les personnes ayant fait appel au 115 ou se présentant en journée en accueil de jour. Il s’agit d’une mise à l’abri temporaire.</p>
                            <p>L’évaluation sociale de l’écoutant est complétée par un entretien avec un travailleur social pour déterminer le maintien en mise à l’abri, la réorientation vers un autre dispositif et un parcours d’insertion.</p>
                        </div>
                        <img src={MVS2} alt="MVS2" />
                        <div className="w-[120px] h-[120px] rounded-full border-4 border-[#FF1D25] absolute -z-50 lg:bottom-40 bottom-28 left-20"></div>
                        <div className="w-[50px] h-[50px] rounded-full border-4 border-[#931212] absolute -z-50 lg:bottom-72 bottom-48 right-6"></div>
                    </div>
                </div>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center lg:space-y-12 space-y-4 lg:pb-32 pb-4 relative">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">Hébergement d'urgence</h2>
                <div className="w-full flex flex-col lg:space-y-8 space-y-2">
                    <p>Notre association <span className="text-[#FF1D25] font-bold">loue différents types d'hébergement</span> (chambre individuelle, studio, studio bis, logement de type T2 à T4...) au sein de résidences sociales et auprès de bailleurs sociaux et privés dans le but de proposer un hébergement adapté.</p>
                    <p>Ces hébergements sont situés autour des zones géographiques suivantes : Vivier au Court, Charleville-Mézières et Sedan. D’autres secteurs géographiques pourront être couvert en fonction des besoins.</p>
                    <p>L'hébergement d'urgence permet de garantir l'hébergement, l'alimentation et l'accompagnement social des publics sans domicile.</p>
                    <div className="flex flex-col spae-y-2">
                        <ul className="list-inside list-disc">
                            <li className="text-[#FF1D25] font-bold">Hébergement</li>
                        </ul>
                        <p>Accueil du public, installation des personnes dans leurs lieux de vie. Signature d’un règlement de fonctionnement et d’un contrat d’hébergement.</p>
                    </div>
                    <div className="flex flex-col spae-y-2">
                        <ul className="list-inside list-disc">
                            <li className="text-[#FF1D25] font-bold">Alimentation</li>
                        </ul>
                        <p>Toute personne sans ressource et ne pouvant assumer son alimentation, bénéficie soit de la convention signée avec les épiceries solidaires, soit du pécule hebdomadaire.</p>
                    </div>
                    <div className="flex flex-col spae-y-2">
                        <ul className="list-inside list-disc">
                            <li className="text-[#FF1D25] font-bold">Accompagnement social</li>
                        </ul>
                        <p>L’accompagnement se déroule au domicile des usagers ou dans les bureaux de l'équipe dédiée à l’Hébergement d’Urgence : mise en place des droits sociaux, intégration des personnes, informations et accompagnement dans les démarches administratives ou sanitaires, aide à la définition et à la mise en œuvre du parcours vers le logement ou dispositif adapté...</p>
                    </div>
                </div>
                <div className="w-[120px] h-[120px] rounded-full border-4 border-[#931212] absolute -z-50 -top-36 left-48"></div>
                <div className="w-[50px] h-[50px] rounded-full border-4 border-[#FF1D25] absolute -z-50 -top-52 left-12"></div>
            </div>
            <BottomWave />
        </div>
    )
}

export default MVS