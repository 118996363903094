import WaveHeader from "../../components/WaveHeader"
import BgWave1 from "../../assets/BgWave1.png"

function Mentions() {
    return(
        <div className="w-full flex flex-col justify-center items-center relative pb-32">
            <div className="w-full flex justify-center items-center relative max-h-[50vh]">
                <img src={BgWave1} alt="BgWave1" className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                <div className="absolute w-full h-full bg-[#931212]/40 top-0"></div>
                <WaveHeader />
                <div className="w-full h-1 absolute bg-white bottom-0"></div>
                <h1 className="absolute top-[15%] text-white font-bold lg:text-7xl text-4xl lg:w-2/3 w-5/6 text-center drop-shadow-xl">MENTIONS LÉGALES</h1>
            </div>
            <div className="w-5/6 flex flex-col justify-start items-start space-y-6">
                <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé aux utilisateurs du site globalaxe.fr l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
                <p className="font-bold text-2xl">Propriétaire</p>
                <p>GLOBAL AXE Association déclarée - n°Siren 818094807 - n° RNA W081003066 - n° TVA Intracommunautaire FR36818094807 - 27 Rue Jules Verne, 08000 Charleville–Mézières</p>
                <p className="font-bold text-2xl">Conception et réalisation</p>
                <p>Agence de Communication COMSEA - Site Internet : www.comsea.fr - Email : contact@comsea.fr</p>
                <p className="font-bold text-2xl">Responsable de publication</p>
                <p>Lola Poiret - l.poiret@globalaxe08.fr</p>
                <p className="font-bold text-2xl">Hébergeur</p>
                <p>Le site globalaxe.fr est hébergé par la société :</p>
                <p>OVH, 2 RUE KELLERMANN 59100 ROUBAIX</p>
                <p className="font-bold text-2xl">Propriété Intellectuelle</p>
                <p>Le présent site Internet pris dans sa globalité et chacun des éléments qui le composent pris indépendamment, notamment les programmes et développements spécifiques et les contenus incluant des données, textes, images fixes ou animées, logotypes, sons, graphiques, fichiers, sont la propriété exclusive du responsable de la publication, du créateur du site Internet ou de tiers qui lui ont concédé une licence. Toute représentation totale ou partielle du site ou de l’un des éléments qui le composent sans l’autorisation expresse du titulaire des droits de propriété intellectuelle est interdite et constituerait une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.</p>
                <p className="font-bold text-2xl">Nous contacter</p>
                <p>Par email : l.poiret@globalaxe08.fr</p>
            </div>
        </div>
    )}

export default Mentions