import { Link } from "react-router-dom"
import accueil from "../../assets/Accueil.png"
import BottomWave from "../../components/BottomWave"
import Icon from "../../assets/Icon.png"


function Accueil() {
    return(
        <div className="w-full flex flex-col justify-center items-center relative lg:pt-20 lg:pb-32 pt-12 pb-20">
            <div className="w-11/12 flex lg:flex-row flex-col justify-between items-start lg:space-y-0 space-y-10">
                <div className="lg:w-[50%] w-full flex flex-col justify-center items-center lg:space-y-12 space-y-6">
                    <div className="flex flex-row lg:space-x-6 space-x-4">
                    <h1 className="lg:text-7xl text-4xl font-bold">GLOBAL</h1>
                    <p className="lg:text-7xl text-4xl font-bold flex flex-row"><img src={Icon} alt="Icon" className="lg:w-[60px] w-[35px]" />XE</p>
                    </div>
                    <p>Spécialisée dans le champ de l'Accueil Hébergement Insertion (AHI) et de l'insertion socioprofessionnelle, l'Association <span className="text-[#FF1D25] font-bold">GLOBAL AXE</span> et ses équipes oeuvrent chaque jour au service de l'Humain.</p>
                    <Link to="/" className="bg-[#931212] rounded-full py-2 px-6 text-white">En savoir plus</Link>
                </div>
                <div className="lg:w-[45%] w-full flex justify-center items-center relative">
                    <img src={accueil} alt="Accueil" className="w-2/3" />
                    <div className="w-[50px] h-[50px] rounded-full border-4 border-[#931212] absolute -z-10 bottom-10 right-24"></div>
                    <div className="w-[150px] h-[150px] rounded-full border-4 border-[#FF1D25] absolute -z-10 -top-5 right-20"></div>
                </div>
            </div>
            <BottomWave />
        </div>
    )
}

export default Accueil