import BottomWave from "../../components/BottomWave"
import WaveHeader from "../../components/WaveHeader"
import BgWave1 from "../../assets/BgWave1.png"
import { HashLink as Link } from "react-router-hash-link"
import PID1 from "../../assets/PID/PID1.png"
import PID2 from "../../assets/PID/PID2.png"
import PID3 from "../../assets/PID/PID3.png"
import PID4 from "../../assets/PID/PID4.png"
import mobilite from "../../flyers/mobilite2.pdf"
import logement from "../../flyers/prepa_loc.pdf"
import emploi from "../../flyers/prepa_job.pdf"
import langue from "../../flyers/asl2.pdf"
import home from "../../flyers/home2.pdf"
import cej from "../../flyers/cej2.pdf"

function PID() {
    return(
        <div className="w-full flex flex-col justify-center items-center relative pb-32">
            <div className="w-full flex justify-center items-center relative max-h-[50vh]">
                <img src={BgWave1} alt="BgWave1" className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                <div className="absolute w-full h-full bg-[#931212]/40 top-0"></div>
                <WaveHeader />
                <div className="w-full h-1 absolute bg-white bottom-0"></div>
                <h1 className="absolute top-[15%] text-white font-bold lg:text-7xl text-3xl lg:w-2/3 w-5/6 text-center drop-shadow-xl">PÔLE INGÉNIERIE & DÉVELOPPEMENT</h1>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center lg:space-y-12 space-y-4 pb-16 relative">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">Qu'est-ce que le Pôle Ingénierie & Développement ?</h2>
                <div className="w-full flex flex-col lg:space-y-8 space-y-2">
                    <p>Ce service propose des outils d’<span className="text-[#FF1D25] font-bold">accompagnements spécifiques</span> en fonction des besoins identifiés auprès des populations les plus fragiles.</p>
                    <div className="flex flex-col space-y-2">
                        <p>Les actions en faveur de l’insertion s’articule autour de 6 dimensions soit : </p>
                        <ul className="list-inside list-disc lg:pl-10 pl-4 space-y-2">
                            <li>L’insertion par la mobilité : <Link to="#mobilite" className="hover:underline">voir</Link></li>
                            <li>L’insertion par le logement : <Link to="#logement" className="hover:underline">voir</Link></li>
                            <li>L’insertion par l’emploi : <Link to="#emploi" className="hover:underline">voir</Link></li>
                            <li>L’insertion par la langue française : <Link to="#langue" className="hover:underline">voir</Link></li>
                            <li>L’insertion des personnes en situation de handicap : <Link to="#handicap" className="hover:underline">voir</Link></li>
                            <li>L’insertion des jeunes en rupture CEJ : <Link to="#jeunes" className="hover:underline">voir</Link></li>
                        </ul>
                    </div>
                </div>
                
                <div className="w-[60px] h-[60px] rounded-full border-4 border-[#FF1D25] absolute z-50 bottom-28 right-96"></div>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center lg:space-y-12 space-y-4 pb-16 relative" id="mobilite">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">L'insertion par la mobilité</h2>
                <div className="w-full flex flex-col lg:space-y-8 space-y-2">
                    <p>La question de la <span className="text-[#FF1D25] font-bold">mobilité</span> peut s’apparenter à un réel obstacle pour s’insérer dans la vie active et notamment professionnelle. Afin de lever ces freins et faciliter l’accès et le maintien à l’emploi, le Service Ingénierie & Développement propose une <span className="text-[#FF1D25] font-bold">mise à disposition de moyens de transport individuel</span>.</p>
                    <p>Le service propose des voitures, des scooters, des vélos et trottinettes à assistance électrique. Sur la base d’un pourcentage de ses ressources, une participation financière sera demandée au bénéficiaire. </p>
                    <div className="flex flex-col space-y-2">
                        <p>Pour obtenir plus d’informations, veuillez contacter : </p>
                        <ul className="list-inside list-disc lg:pl-10 pl-4 space-y-2">
                            <li>mobilite@globalaxe08.fr</li>
                            <li>06.04.65.91.11</li>
                        </ul>
                    </div>
                </div>
                <a href={mobilite} className="bg-[#FF1D25] rounded-full py-2 px-12 text-white" download="mobilité.pdf">Flyer</a>
                <div className="w-[80px] h-[80px] rounded-full border-4 border-[#FF1D25] absolute z-50 -top-20 -left-12"></div>
                <div className="lg:w-[150px] lg:h-[150px] w-[100px] h-[100px] rounded-full border-4 border-[#931212] absolute z-50 lg:-top-44 -top-0 lg:-right-6 -right-12"></div>
                <div className="w-[40px] h-[40px] rounded-full border-4 border-[#FF1D25] absolute z-50 bottom-20 right-0"></div>
                <div className="w-[100px] h-[100px] rounded-full border-4 border-[#931212] absolute z-50 bottom-0 right-72"></div>
            </div>
            <div className="w-5/6 flex flex-col justify-between items-center pb-16" id="logement">
                <div className="w-full flex lg:flex-row flex-col justify-between items-center pb-8 lg:space-y-0 space-y-4">
                    <div className="lg:w-[50%] w-full flex flex-col justify-center items-center lg:space-y-12 space-y-4">
                        <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">L'insertion par le logement</h2>
                        <div className="w-full flex flex-col justify-center items-center lg:space-y-8 space-y-2">
                            <p>Afin de favoriser l'accès et le maintien dans le <span className="text-[#FF1D25] font-bold">logement autonome</span> des publics sans logement ou mettant en péril leur engagement locatif, le service propose des ateliers collectifs (individuels si besoin). </p>
                            <p>Ces ateliers s’organisent autour de trois modules différents traitant des thématiques liées à la gestion du budget et de l’administratif, au projet locatif, à la prévention des risques ou encore à la consommation d’énergie. Les ateliers s’effectuent au sein d’un appartement pédagogique permettant l’apprentissage, dans un environnement immersif.</p>
                            <div className="flex flex-col space-y-2">
                                <p>Pour obtenir plus d’informations concernant les modalités d’inscription et le contenu des ateliers, veuillez contacter : </p>
                                <ul className="list-inside list-disc lg:pl-10 pl-4 space-y-2">
                                    <li>c.boge@globalaxe08.fr</li>
                                    <li>06.41.03.39.29</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center relative">
                        <img src={PID1} alt="PID1" className="w-[65%]" />
                    </div>
                </div>
                <a href={logement} className="bg-[#FF1D25] rounded-full py-2 px-12 text-white" download="logement.pdf">Flyer</a>
            </div>
            <div className="w-5/6 flex flex-col justify-between items-center pb-16 relative" id="emploi">
                <div className="w-full flex lg:flex-row flex-col-reverse justify-between items-center pb-8">
                    <div className="lg:w-[45%] w-full flex justify-center items-center relative">
                        <img src={PID2} alt="PID2" className="w-[65%]" />
                        <div className="w-[40px] h-[40px] rounded-full border-4 border-[#FF1D25] absolute -z-50 bottom-20 right-20"></div>
                        <div className="w-[180px] h-[180px] rounded-full border-4 border-[#931212] absolute -z-50 lg:-top-12 -top-6 right-72"></div>
                    </div>
                    <div className="lg:w-[50%] w-full flex flex-col justify-center items-center lg:space-y-12 space-y-4 lg:pb-0 pb-4">
                        <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">L'insertion par l'emploi</h2>
                        <div className="w-full flex flex-col justify-center items-center lg:space-y-8 space-y-2">
                            <p>Pour toute personne ayant rapidement besoin d’un coup de pouce afin de se <span className="text-[#FF1D25] font-bold">préparer à l’emploi</span> ou de se <span className="text-[#FF1D25] font-bold">familiariser avec le monde du travail</span>, le Service Ingénierie & Développement propose un accompagnement individuel ou des ateliers collectifs.</p>
                            <p>Du test d’orientation professionnelle, en passant par l’aide à la rédaction d’un CV jusqu’à la recherche d’emploi, le service propose divers modules pour préparer le retour à l’emploi et réinstaurer un lien entre la personne et la structure d’accompagnement vers l’emploi (Pole Emploi, Mission Locale, Cap emploi).</p>
                            <div className="flex flex-col space-y-2">
                                <p>Pour toute question concernant les contenus des ateliers, les horaires de permanences ou les modalités d’inscription, veuillez contacter : </p>
                                <ul className="list-inside list-disc lg:pl-10 pl-4 space-y-2">
                                    <li>l.poncelet@globalaxe08.fr</li>
                                    <li>07.84.85.87.78</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <a href={emploi} className="bg-[#FF1D25] rounded-full py-2 px-12 text-white" download="emploi.pdf">Flyer</a>
                <div className="w-[40px] h-[40px] rounded-full border-4 border-[#FF1D25] absolute -z-50 bottom-32 right-52"></div>
                <div className="w-[160px] h-[160px] rounded-full border-4 border-[#931212] absolute -z-50 lg:-bottom-6 bottom-0 lg:right-0 -right-8"></div>
            </div>
            <div className="w-5/6 flex flex-col justify-between items-center pb-16 lg:space-y-8 space-y-4" id="langue">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">L'insertion par la langue française</h2>
                <p className="w-full">Dans le cadre d’<span className="text-[#FF1D25] font-bold">Ateliers Sociolinguistiques</span> (ASL PRO), un apprentissage de la langue française est proposé aux personnes : </p>
                <ul className="w-full list-inside list-disc lg:pl-10 pl-4 lg:space-y-2 space-y-1">
                    <li>Etrangères primo-arrivantes, signataires du CIR, ressortissantes d’un pays tiers à l’Union Européenne, titulaire depuis moins de cinq ans d'un titre de séjour délivré au titre de l'immigration familiale, économique ou d'une protection internationale. </li>
                    <li>Agées de 18 ans et plus </li>
                    <li>D’un niveau A1 minimum </li>
                </ul>
                <div className="w-full flex lg:flex-row flex-col justify-between items-center pb-8">
                    <div className="lg:w-[50%] w-full flex flex-col justify-center items-center lg:space-y-8 space-y-2">
                        <p>A travers des thématiques liées à l’insertion professionnelle, la vie sociale ou la citoyenneté, les bénéficiaires travaillent le français en collectif.</p>
                        <p>Sont également dispensés des Ateliers de Français Langues Professionnelles (AFL PRO) consistant à développer des compétences linguistiques orales et écrites propres à un champ d'activité spécifique (bâtiment, industrie, santé, hôtellerie…). </p>
                        <div className="flex flex-col space-y-2">
                            <p>Pour toute question concernant les ateliers ou les modalités d’inscription, veuillez contacter : </p>
                            <ul className="list-inside list-disc lg:pl-10 pl-4 lg:space-y-2 space-y-1">
                                <li>l.poncelet@globalaxe08.fr</li>
                                <li>07.84.85.87.78</li>
                            </ul>
                        </div>
                    </div>
                    <div className="lg:w-[45%] w-full flex justify-center items-center relative pt-4">
                        <img src={PID3} alt="PID3" className="w-[65%]" />
                    </div>
                </div>
                <a href={langue} className="bg-[#FF1D25] rounded-full py-2 px-12 text-white" download="langue.pdf">Flyer</a>
            </div>
            <div className="w-5/6 flex flex-col justify-between items-center pb-16" id="handicap">
                <div className="w-full flex lg:flex-row flex-col-reverse justify-between items-center pb-8">
                    <div className="lg:w-[45%] w-full flex justify-center items-center relative pt-4">
                        <img src={PID4} alt="PID4" className="w-[65%]" />
                        <div className="w-[50px] h-[50px] rounded-full border-4 border-[#FF1D25] absolute -z-50 lg:-top-40 top-12 lg:right-32 right-12"></div>
                        <div className="w-[120px] h-[120px] rounded-full border-4 border-[#931212] absolute -z-50 lg:-top-28 top-2 left-0"></div>
                    </div>
                    <div className="lg:w-[50%] w-full flex flex-col justify-center items-center lg:space-y-12 space-y-4">
                        <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">L'insertion des personnes en situation de handicap</h2>
                        <div className="w-full flex flex-col justify-center items-center lg:space-y-8 space-y-2">
                            <p>« Habiter Oui, Mais Ensemble » telle est la devise du dispositif d’<span className="text-[#FF1D25] font-bold">habitat inclusif « HOME »</span> à destination des personnes : </p>
                            <ul className="list-inside list-disc lg:pl-10 pl-4 lg:space-y-2 space-y-1">
                                <li>En situation de handicap psychique ou intellectuel</li>
                                <li>Suffisamment autonome pour progresser et s’épanouir dans un logement</li>
                                <li>Bénéficiaire de l’AAH, PCH, ACTP ou pension d’invalidité 2e ou 3e catégorie</li>
                                <li>Seule, en couple, avec ou sans enfant</li>
                            </ul>
                            <p>Il s’agit d’un espace de vie collective situé sur la commune de Sedan (08200), à proximité de douze logements autonomes au sein desquels vivent indépendamment les bénéficiaires.</p>
                            <p>Ce dispositif, fondé sur une volonté de soutenir l’autonomie, de créer du lien social et rompre l’isolement, propose un accompagnement aux personnes sur des temps collectifs. et individuels.</p>
                            <div className="flex flex-col space-y-2">
                                <p>Pour obtenir plus de renseignements sur «HOME», veuillez contacter : </p>
                                <ul className="list-inside list-disc lg:pl-10 pl-4 lg:space-y-2 space-y-1">
                                    <li>06.68.26.81.99</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <a href={home} className="bg-[#FF1D25] rounded-full py-2 px-12 text-white" download="home.pdf">Flyer</a>
            </div>
            <div className="w-5/6 flex flex-col justify-center items-center lg:space-y-12 space-y-4 lg:pb-16 pb-4 relative" id="jeunes">
                <h2 className="lg:text-4xl text-3xl font-bold lg:w-1/2 w-11/12 text-center">L'insertion des jeunes en rupture CEJ</h2>
                <div className="w-full flex flex-col lg:space-y-8 space-y-2">
                    <p>Pour les jeunes âgés de 16 à 25 ans (29 en situation de handicap) en rupture, c’est-à-dire sans accompagnement Pôle Emploi/Mission Locale depuis plus de 5 mois, sans emploi ni formation, le service Ingénierie & Développement propose un accompagnement individualisé permettant la levée des freins (mobilité, logement).</p>
                    <p>L’objectif est de travailler l’insertion professionnelle en co-accompagnement avec la Mission Locale de secteur. Les actions mises en place sont : </p>
                    <ul className="list-inside list-disc lg:pl-10 pl-4 lg:space-y-2 space-y-1">
                        <li>La mise à disposition de logement en cohabitation équipés de moyens de déplacement proche des lieux d'insertion professionnelle (centre de formation…).</li>
                        <li>La mise à disposition de véhicules pour faciliter la mobilité sur l’ensemble du département des Ardennes .</li>
                    </ul>
                    <div className="flex flex-col space-y-2">
                        <p>Pour plus d’informations, veuillez contacter : </p>
                        <ul className="list-inside list-disc lg:pl-10 pl-4 lg:space-y-2 space-y-1">
                            <li>06.25.29.58.13</li>
                        </ul>
                    </div>
                </div>
                <a href={cej} className="bg-[#FF1D25] rounded-full py-2 px-12 text-white" download="cej.pdf">Flyer</a>
                <div className="w-[50px] h-[50px] rounded-full border-4 border-[#FF1D25] absolute -z-50 -top-28 right-12"></div>
                <div className="lg:w-[150px] lg:h-[150px] w-[70px] h-[70px] rounded-full border-4 border-[#931212] absolute -z-10 lg:bottom-20 lg:right-28 bottom-10 right-16"></div>
            </div>
            <BottomWave />
        </div>
    )
}

export default PID