import { Card, CardActions, CardContent, CardMedia } from "@mui/material"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import axios from 'axios';

function Posts() {
    const [isLoading, setIsLoading] = useState(true)
    const [posts, setPosts] = useState(null)

    useEffect(() => {
        axios.get('https://api.globalaxe.fr/api/actualites')
          .then((response) => {
            setPosts(response.data['hydra:member']);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error(error);
            // Gérez les erreurs ici, si nécessaire
          });
      }, []);

    console.log(posts, 'Oui')

    return(
        <div className="w-full flex justify-center items-center lg:pt-0 pt-6 lg:pb-28 pb-8 relative">
            <div className="w-5/6 grid lg:grid-cols-2 grid-cols-1 gap-x-16 gap-y-8 z-50">
                {isLoading ? 'Chargement en cours':  posts.slice(0).reverse().map(post => 
                    <div className="bg-[#EEEEEE] rounded-xl p-4 flex flex-col justify-between space-y-5">
                        <div className="flex lg:flex-row flex-col justify-between items-start lg:space-y-0 space-y-5">
                            <div className="lg:w-[50%] w-full">
                                <img src={"https://api.globalaxe.fr/build/images/"+post.image} className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                            </div>
                            <div className="lg:w-[48%] w-full h-full text-center flex justify-center items-center">
                                <h3 className="text-3xl font-bold">{post.title}</h3>
                            </div>
                        </div>
                        <div>
                            <p className="line-clamp-4" dangerouslySetInnerHTML={{ __html: post.description }} />
                        </div>
                        <div className="flex flex-row justify-between items-center">
                            <p className="text-lg text-[#555555]">{(new Date(post.createdAt)).toLocaleDateString()}</p>
                            <Link to={`/actualite/${post.id}`} className="bg-[#FF1D25] text-white py-2 px-5 rounded-full">Voir l'article</Link>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Posts