import BottomWave from "../../components/BottomWave"
import WaveHeader from "../../components/WaveHeader"
import BgWave1 from "../../assets/BgWave1.png"
import Chantiers1 from "../../assets/Chantiers/Chantiers1.png"

function Chantiers() {
    return(
        <div className="w-full flex flex-col justify-center items-center relative pb-32">
            <div className="w-full flex justify-center items-center relative max-h-[50vh]">
                <img src={BgWave1} alt="BgWave1" className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                <div className="absolute w-full h-full bg-[#931212]/40 top-0"></div>
                <WaveHeader />
                <div className="w-full h-1 absolute bg-white bottom-0"></div>
                <h1 className="absolute top-[15%] text-white font-bold lg:text-7xl text-4xl lg:w-2/3 w-5/6 text-center drop-shadow-xl">CHANTIERS D'INSERTION</h1>
            </div>
            <div className="w-5/6 flex lg:flex-row flex-col justify-between items-center lg:pb-32 pb-12 lg:space-y-0 space-y-12">
                <div className="lg:w-[50%] w-full flex flex-col justify-center items-center space-y-12 relative">
                    <h2 className="lg:text-4xl text-3xl font-bold text-center">Qu'est-ce que les Chantiers d'Insertion ?</h2>
                    <div className="w-full flex flex-col justify-center items-start lg:space-y-8 space-y-4">
                        <p>Ce service a ouvert des <span className="text-[#FF1D25] font-bold">ateliers et chantiers d’insertion</span> à Bogny-sur-Meuse avec comme support la confection textile. Il repose sur deux objectifs :</p>
                        <ul className="list-inside list-disc lg:pl-10 pl-4 lg:space-y-2 space-y-1">
                            <li>L’insertion professionnelle</li>
                            <li>La relocalisation française de la production</li>
                        </ul>
                        <p>Au sein de cette unité, une accompagnatrice socio-professionnel guide les salarié.e.s tout au long de leur parcours de transition professionnelle durant leur temps de travail. Elle accompagne les personnes dans leur démarche administratives, l’élaboration d’un projet professionnel, le tissage de lien avec les entreprises, la recherche de stage et d’emploi tout en participant activement à la levée des freins.</p>
                        <p>Cet atelier de couture de 350m2 accueille aujourd’hui une trentaine de salarié.e.s.</p>
                        <p>Pour plus d’informations, consultez le site d’<a href="https://www.ambitionpro.fr" className="hover:underline" target="_blank" rel="noreferrer">AMBITION PRO</a>.</p>
                        <div className="w-[50px] h-[50px] rounded-full border-4 border-[#FF1D25] absolute -z-50 top-6 -left-10"></div>
                    </div>
                </div>
                <div className="lg:w-[45%] w-full flex justify-center items-center relative">
                    <img src={Chantiers1} alt="Chantiers1" className="z-40" />
                    <div className="w-[100px] h-[100px] rounded-full border-4 border-[#FF1D25] absolute z-30 -bottom-20 -right-10"></div>
                    <div className="w-[200px] h-[200px] rounded-full border-4 border-[#931212] absolute z-30 -top-5 -right-0"></div>
                </div>
            </div>
            <BottomWave />
        </div>
    )
}

export default Chantiers