import { Link } from "react-router-dom"

function Footer() {
    return(
        <div className="w-full flex flex-col justify-center items-center text-white">
            <div className="w-full py-10 bg-gradient-to-r from-[#931212] to-[#FF1D25] flex flex-col justify-center items-center space-y-8">
                <div className="grid lg:grid-cols-3 grid-cols-1 w-5/6 gap-x-10 gap-y-12">
                    <div className="flex flex-col justify-start items-center lg:space-y-10 space-y-6">
                        <p className="text-2xl text-center font-semibold">MAISON DE LA VEILLE SOCIALE</p>
                        <div>
                            <p>Tél: 03.24.27.12.73</p>
                        </div>
                    </div>
                    <div className="flex flex-col justify-start items-center lg:space-y-10 space-y-6">
                        <p className="text-2xl text-center font-semibold">SERVICE MVS</p>
                        <div>
                            <p>SIAO 08: 03.24.27.12.74</p>
                            <p>SERVICE 115: 115</p>
                            <p>ACCUEIL DE JOUR: 07.84.64.80.02</p>
                            <p>ACCUEIL DE NUIT: 03.24.22.30.93</p>
                        </div>
                    </div> 
                    <div className="flex flex-col justify-start items-center lg:space-y-10 space-y-6">
                        <p className="text-2xl text-center font-semibold">PÔLE D'INGÉNIERIE SOCIALE ET DÉVELOPPEMENT</p>
                        <div>
                            <p>Tél: 03.24.59.16.76</p>
                        </div>
                    </div>
                </div>
                <p className="w-5/6 flex justify-center items-center">53 Avenue Léon Bourgeois, 08000 Charleville-Mézières</p>
            </div>
            <div className="w-full bg-black text-base font-normal flex flex-row justify-center items-center py-4 space-x-2">
                    <p className="w-11/12 text-center">© 2023 Copyright : <a href="https://www.comsea.fr" target="_blank" className="hover:underline" rel="noreferrer">COMSEA</a> | Tous droits Réservés | <Link to="/mentions" className="hover:underline">Mentions légales</Link> | <Link to="/confidentialite" className="hover:underline">Politique de confidentialité</Link></p>
            </div>
        </div>
    )
}

export default Footer