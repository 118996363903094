import Logo from "../../assets/Logo.png"
import Header1 from "../../assets/Header1.png"
import Header2 from "../../assets/Header2.png"

function Header() {
    return(
        <div className="w-full flex flex-col justify-center items-center lg:py-20 py-12 lg:space-y-16 space-y-10 relative overflow-hidden">
            <div className="lg:w-1/5 w-1/2">
                <img src={Logo} alt="Logo" className="w-full"/>
            </div>
            <div className="w-11/12 flex justify-start items-start lg:font-bold font-semibold lg:text-2xl text-xl">
                <div className="lg:w-2/3 w-11/12">
                    <p><span className="text-[#FF1D25] font-bold">GLOBAL AXE</span> est une association loi 1901 créée à l’initiative d’acteurs des champs de l’intervention sociale et de l’hébergement</p>
                </div>
            </div>
            <img src={Header1} alt="Header1" className="absolute lg:-top-[35%] -top-24 -left-[13%] rotate-[25deg] w-[35%]" />
            <img src={Header2} alt="Header2" className="absolute lg:-top-[27%] top-0 -right-[5%] rotate-[0deg] w-[35%]" />
            <div className="w-[150px] h-[150px] rounded-full border-4 border-[#931212] absolute -z-10 lg:top-[5%] -top-8 lg:right-[20%] -right-20"></div>
            <div className="w-[70px] h-[70px] rounded-full border-4 border-[#FF1D25] absolute -z-10 lg:top-[30%] top-10 lg:left-[15%] left-5"></div>
            <div className="w-[40px] h-[40px] rounded-full border-4 border-[#FF1D25] absolute -z-10 lg:-top-[3%] top-[50%] lg:right-[33%] right-2"></div>
        </div>
    )
}

export default Header