import Wave from "../../assets/Wave1.png"

function BottomWave() {
    return(
        <div className="w-full absolute bottom-0 -z-20">
            <img src={Wave} alt="Wave" className="w-full" />
        </div>
    )
}

export default BottomWave