import Wave from "../../assets/Wave2.png"

function WaveHeader() {
  return (
    <div className="absolute w-full bottom-0 z-20">
        <img src={Wave} alt="Wave Header" />
    </div>
  )
}

export default WaveHeader