import { Link } from "react-router-dom"
import Icon from "../../assets/Icon.png"
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import * as React from 'react';

function Navbar() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const theme = createTheme({
        palette: {
          primary: {
            main: '#FF1D25',
          },
          secondary: {
            main: '#931212',
          },
        },
      });

    return(
        <div className="w-full py-3 flex justify-center items-center shadow-[0_10px_10px_2px_rgba(0,0,0,0.3)]">
            <div className="w-11/12 lg:flex hidden flex-row justify-between items-center">
                <Link to="/" className="w-[200px]">
                    <img src={Icon} alt="Icon" className="w-[40px]" />
                </Link>
                <div className="flex flex-row space-x-8">
                    <Link to="/">
                        Accueil
                    </Link>
                    <Link to="/presentation">
                        Présentation
                    </Link>
                    <Link to="/mvs">
                        MVS
                    </Link>
                    <Link to="/pid">
                        PID
                    </Link>
                    <Link to="/chantiers">
                        Chantiers d'Insertion
                    </Link>
                    <Link to="/actualites">
                        Actualités
                    </Link>
                </div>
                <Link to="/contact" className="w-[200px] bg-[#FF1D25] text-white rounded-[999px] text-center py-2">
                    Contact
                </Link>
            </div>
            <div className="w-11/12 lg:hidden flex flex-row justify-between items-center">
                <Link to="/" className="w-[200px]">
                    <img src={Icon} alt="Icon" className="w-[40px]" />
                </Link>
                <div>
                    <ThemeProvider theme={theme}>
                        <Button
                            id="basic-button"
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                        >
                            <MenuIcon color="secondary" />
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                            color="primary"
                        >
                            <MenuItem onClick={handleClose}><Link to="/" className="w-full">Accueil</Link></MenuItem>
                            <MenuItem onClick={handleClose}><Link to="/presentation" className="w-full">Présentation</Link></MenuItem>
                            <MenuItem onClick={handleClose}><Link to="/mvs" className="w-full">MVS</Link></MenuItem>
                            <MenuItem onClick={handleClose}><Link to="/pid" className="w-full">PID</Link></MenuItem>
                            <MenuItem onClick={handleClose}><Link to="/chantiers" className="w-full">Chantiers d'Insertion</Link></MenuItem>
                            <MenuItem onClick={handleClose}><Link to="/actualites" className="w-full">Actualités</Link></MenuItem>
                            <MenuItem onClick={handleClose}><Link to="/contact" className="w-[200px] bg-[#FF1D25] text-white rounded-[999px] text-center py-2">Contact</Link></MenuItem>
                        </Menu>
                    </ThemeProvider>
                </div>
            </div>
        </div>
    )
}

export default Navbar