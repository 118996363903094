import BottomWave from "../../components/BottomWave"
import WaveHeader from "../../components/WaveHeader"
import BgWave1 from "../../assets/BgWave1.png"
import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

function Actualite() {
    const {id} = useParams()
    let [postState, setPostState] = useState([])
    const [galeries, setGaleries] = useState([])
    let [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch(`https://api.globalaxe.fr/api/actualites/${id}`)
        .then((response) => {
            response = response.json()
            response.then((result) => {
                setPostState(result)
                setIsLoading(false)
            })})
    }, [])

    useEffect(() => {
        fetch(`https://api.globalaxe.fr/api/images`)
        .then((response) => {
            response = response.json()
            response.then((result) => {
                setGaleries(result['hydra:member'])
                setIsLoading(false)
            })})
    }, [])

    console.log(postState.gallery)
    console.log(galeries)

    return(
            <div className="w-full flex flex-col justify-center items-center relative pb-32">
                <div className="w-full flex justify-center items-center relative max-h-[50vh]">
                    <img src={"https://api.globalaxe.fr/build/images/"+postState.image} className="w-full max-h-[50vh] object-cover border-b-2 border-white" />
                    <div className="absolute w-full h-full bg-[#931212]/40 top-0"></div>
                    <WaveHeader />
                    <div className="w-full h-1 absolute bg-white bottom-0"></div>
                    <h1 className="absolute top-[15%] text-white font-bold lg:text-7xl text-4xl lg:w-[75%] w-11/12 text-center drop-shadow-xl">{postState.title}</h1>
                </div>
                <div className="w-full flex flex-col items-center lg:pb-28 lg:pt-0 pt-16 relative">
                    <div className="absolute lg:-top-20 top-0 lg:left-16 left-4 z-50">
                        <Link to="/actualites" className="bg-[#FF1D25] text-white py-2 px-5 rounded-full">Retour</Link>
                    </div>
                    <div className="w-5/6 flex flex-col items-start justify-start space-y-10 z-50">
                        <p dangerouslySetInnerHTML={{ __html: postState.description }} />
                        <div className="flex flex-col justify-center items-center w-full space-y-8">
                            <h2 className="lg:text-4xl text-3xl font-bold text-center">Galerie photo</h2>
                            <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-5">
                            {isLoading ? "Chargement en cours" : galeries.map(galerie => (
                                <img src={postState.gallery === "/api/images/"+galerie.id ? "Image en cours de chargement" : "https://api.globalaxe.fr/build/images/"+galerie.path } alt="" />
                            ))}
                            </div> 
                        </div>
                        <div className="w-full">
                            <p className="text-lg text-[#555555]">{(new Date(postState.createdAt)).toLocaleDateString()}</p>
                        </div>
                    </div>
                    <div className="w-[100px] h-[100px] rounded-full border-4 border-[#931212] absolute z-30 bottom-44 left-10"></div>
                    <div className="w-[50px] h-[50px] rounded-full border-4 border-[#931212] absolute z-30 lg:-top-20 -top-4 right-16"></div>
                    <div className="w-[100px] h-[100px] rounded-full border-4 border-[#FF1D25] absolute z-30 bottom-[25rem] right-36"></div>
                </div>
                <BottomWave />
            </div>
    )
}

export default Actualite