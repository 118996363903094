import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"

export const Test = () => {
    const {id} = useParams()
    let [isLoading, setIsLoading] = useState(true)
    const [actu, setActu] = useState([])

    useEffect(() => {
        fetch(`https://127.0.0.1:8000/api/actualites/1`)
        .then((response) => {
            response = response.json()
            response.then((result) => {
                setActu(result)
                setIsLoading(false)
            })})
    }, [])

    return(
        <div>
            <p>{actu.title}</p>
        </div>
    )
}